<template lang="pug">
div
  slot(name="activator" :toggle="toggle")
    v-btn(small color='primary'
      @click="toggle"
      :disabled="disabled"
      outlined data-test="c-exchange"
    ) {{ title }}
  v-dialog(v-model="showing" scrollable max-width=600)
    v-card(data-test="c-exchange-body")
      v-card-title(data-test="c-exchange-title") {{ title }}
      v-card-text
        reservations(:formErrors="formErrors" ref="reservations" addText="add exam"
          :getExamLocation="getExamLocation"
          :getCourseLocation="getCourseLocation"
          dontMarkManually
          :model="model" :avaliableSlots="false" :onHoldShowing="false")
        v-text-field(
          v-model="subject"
          :loading="templateLoading"
          :error="formErrors.hasError('subject')"
          :error-messages="formErrors.fields.subject"
          label="Subject"
        )
        v-textarea(
          v-model="template"
          :loading="templateLoading"
          :disabled="templateLoading"
          rows='10' outlined
        label="Email template"
          :error="formErrors.hasError('template')"
          :error-messages="formErrors.fields.template"
        ).mt-3
        div.text-center.mt-3

      v-card-actions
        v-btn(color="warning" text @click="saveTemplate") save template
        v-spacer
        v-btn(color="error" text @click="toggle") Close
        v-btn(color="success" data-test="c-exchange-submit" text @click="submit") send emails
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin'
import FormErrors from "@/util/form-errors"
import { mapActions } from 'vuex'
import Model from "../core/candidates-reservation-model"
import { SUGGEST_EXAM_TEMPLATE, API_ATTRIBUTES_NESTED, SUGGEST_EXAM_SUBJECT } from '../core/candidates-const'
import CandidateRequestsValidator from '../core/models/validation/CandidateRequestsValidator'
import { convertToDefaultDateFormat } from '@/util'
import { STORAGE } from "@/util/const"

export default {
  mixins: [errorsMixin],
  props: {
    disabled: Boolean,
    activeItems: Array,
    isExchange: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Suggest exams'
    },
  },
  data: () => ({
    showing: false,
    formErrors: new FormErrors(),
    model: new Model(),
    template: '',
    subject: '',
    templateLoading: false,
  }),

  computed: {},

  inject: ['svc'],

  methods: {
    ...mapActions({
      getExamLocation: 'crmExamLocation/list',
      getCourseLocation: 'crmCourseCities/list'
    }),
    toggle() {
      this.showing = !this.showing
    },

    async getTemplateData() {
      try {
        this.templateLoading = true
        let {subjectField, contentField} = this.getTemplateFields()
        let res = await this.$store.dispatch('storage/get', [subjectField, contentField])
        this.template = res[contentField] ? res[contentField] : SUGGEST_EXAM_TEMPLATE
        this.subject = res[subjectField] || SUGGEST_EXAM_SUBJECT
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.templateLoading = false
      }
    },

    getTemplateFields() {
      let subjectField = this.isExchange ? STORAGE.SUGGEST.EXCHANGE.SUBJECT : STORAGE.SUGGEST.EXAM.SUBJECT
      let contentField = this.isExchange ? STORAGE.SUGGEST.EXCHANGE.CONTENT : STORAGE.SUGGEST.EXAM.CONTENT
      return {subjectField, contentField}
    },

    async saveTemplate() {
      try {
        this.templateLoading = true
        let {subjectField, contentField} = this.getTemplateFields()
        let res = await this.$store.dispatch('storage/set', {
          [subjectField]: this.subject,
          [contentField]: this.template
        })
        this.$notify({type: 'success', text: 'template saved'})
      } catch (error) {

        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.templateLoading = false
      }
    },

    Svc() {
      return this.svc()
    },

    submit() {
      this.model.requests = this.$refs.reservations.requests
      this.formErrors.resetFields()
      let exchangeRequests = this.model._getRequestsApiDataBy(this.$refs.reservations.requests)
      new CandidateRequestsValidator(this.model.requests, this.formErrors, 'requests').validate()
      this.validateTemplate(this.formErrors)
      if (exchangeRequests.length) {
        let fieldsNotFilled = Object.values(exchangeRequests[0]).every(field => !field)
        if (fieldsNotFilled) {
          this.$notify({text: 'Please, fill fields before sent', type: 'error'})
          return
        }
      }
      if (!this.formErrors.isValid()) {
        this.$notify({text: 'Invalid fields', type: 'error'})
        return
      }
      this.send()
    },

    validateTemplate(formErrors) {
      if (!this.subject) formErrors.invalidField('subject', 'required fields')
      if (!this.template) formErrors.invalidField('template', 'required fields')
    },

    async send() {
      try {
        let ids = this.activeItems.map(item => item.ID)
        let exchangeRequests = this.model._getRequestsApiDataBy(this.$refs.reservations.requests)
        let res = await this.svc().sendExchange({
          ids: ids,
          exchangeRequests: exchangeRequests,
          content: this.template,
          subject: this.subject,
          isExchange: this.isExchange
        })
        this.$notify({text: 'Exchange list sended', type: 'success'})
        this.toggle()
        this.$emit('send', res)
      } catch (error) {
        console.log(error)
        this.processErrorWithForm(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
          formErrors: this.formErrors,
          apiAttributes: {
            exams: 'requests'
          },
          nestedErrors: API_ATTRIBUTES_NESTED
        });
        // this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },

    fillExam(exams, examID) {
      this.formData.exam.ID = examID
      let selectedExam = exams.find(exam => exam.ID === examID)
      if (!selectedExam) return
      if (!this.formData.examDate) this.item.examDate = convertToDefaultDateFormat(selectedExam.date)
      if (!this.formData.timeFrom) this.item.timeFrom = selectedExam.timeFrom
      if (!this.formData.timeTo) this.item.timeTo = selectedExam.timeTo
      if (!this.formData.location) this.item.location = selectedExam.location
    }
  },

  watch: {
    showing(isShowing) {
      if (!isShowing) {
        this.model = new Model()
        this.$refs.reservations.resetRequests()
        return
      }
      this.getTemplateData()
    }
  },

  components: {
    exchangeItem: () => import('./CandidatesExchangeItem.vue'),
    reservations: () => import('./item/CanditeReservations.vue')
  }
}
</script>
